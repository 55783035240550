// Large devices (desktops, less than 1370px)
@media (max-width: 1366.98px) {

	section.section-welcome {
		.welcome-content {
			.wrapper-carousel {
				padding-top: 100px;
			}

		}
	}
}

@media (max-width: 1189.98px) {

	.color-elem-nav {
		right: 31%;

	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

	nav.section-header {
		.header-flexbox {
			.flexbox-nav-wrapper {
				.flexbox-nav-inner {
					.flexbox-nav {
						width: 100%;
					}

					.nav-contact-list {
						display: none;
					}

					.color-elem-nav {
						right: -5%;
						top: -270%;

					}
				}
			}
		}
	}

	section.section-welcome {

		.welcome-content {

			.heading-text-inner {
				width: 300px;
			}

			.carousel-content {

				.owl-carousel {

					.heading {

						h1,
						h2 {

							font-size: 40px;
						}
					}
				}
			}
		}
	}

	section.section-employer {
		.employer-overlay {
			.overlay-wrapper {
				padding: 0 30px;

				h3 {
					font-size: 30px;
				}
			}
		}
	}


	section.section-workers {
		.workers-content {
			.workers-description {
				padding: 0 30px;

				h3 {
					font-size: 30px;
				}
			}

			.workers-opinion {
				padding: 50px;
			}
		}
	}

	section.section-offer-details {
		.details-wrapper {
			.details-item {
				height: 150px;
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

	nav.section-header {
		.header-flexbox {
			.nav-button {
				display: flex;
				margin-right: -10px;

				.button-bar {
					background: @color1;
				}
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;
						align-items: center;
						width: 100%;

						li {
							padding: 10px 0;

							form {
								display: none;
							}

							a {
								color: #444;
								font-size: 20px;
								font-weight: 400;
							}

							&:last-of-type {

								a {
									color: @color1;
								}
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	section.section-welcome {

		.welcome-content {

			.heading-text-inner {
				width: 240px;
			}

			.carousel-content {

				.owl-carousel {

					.heading {

						h1,
						h2 {

							font-size: 30px;
						}
					}
				}
			}
		}
	}

	section.section-about {
		.about-wrapper {
			flex-direction: column;
			align-items: center;

			.wrapper-text {
				width: 100%;
			}

			.wrapper-img {
				width: 80%;
				height: 300px;
			}
		}
	}

	section.section-offer {
		.offer-tiles-wrapper {
			.tile {
				padding: 10px;

				.icon {
					img {
						width: 40px;
					}
				}
			}
		}
	}

	section.section-offer-details {
		padding: 0 0 60px 0;

		.details-wrapper {
			.details-item {
				height: 100px;
			}

			.item-overlay {
				h4 {
					font-size: 14px;
				}
			}
		}
	}

	section.kontakt-section-content {
		padding: 60px 0 100px 0;

		h2 {
			font-size: 40px;
		}

		.contact-wrapper {
			.contact-column {
				flex-direction: column;

				.text {
					width: 100%;
				}

				.iframe-map {
					width: 100%;
					margin-top: 50px;
					height: 250px;

					iframe {
						max-height: 250px;
					}

					.color-elem {
						top: 23%;
						left: -2%;
						width: 250px;
						height: 200px;

					}
				}
			}

			.contact-form {
				form {
					width: 100%;
					margin: 0;
				}
			}
		}
	}

	section.section-footer {
		padding: 0;

		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				text-align: center;
				margin-top: 20px;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 680px)
@media (max-width: 680.98px) {
	section.section-workers {
		.workers-wrapper {
			flex-direction: column;
			align-items: center;

			.workers-content {
				width: 100%;

				&:nth-of-type(1) {
					width: 80%;
					transform: translateY(0);
				}

				&:nth-of-type(2) {
					padding: 50px 0;
				}

				.workers-description {
					h3 {
						font-size: 30px;
					}
				}

				.workers-opinion {
					width: 100%;
				}
			}
		}
	}

	section.section-offer-details {
		.details-wrapper {
			flex-direction: column;
			align-items: center;

			.details-item {
				width: 50%;
				height: 250px;

				.item-overlay {
					h4 {
						font-size: 18px;
					}
				}
			}
		}
	}
}


// Extra small devices (portrait phones, less than 680px)
@media (max-width: 579.98px) {

	.modal {
		.contact-form {
			padding: 0 !important;
		}
	}

	section.section-welcome {
		.welcome-text {
			padding: 80px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			color: #fff;

			.heading-text-inner {
				width: 80%;
			}
		}

		.welcome-content {
			.carousel-content {
				display: none;
			}
		}
	}

	section.section-offer {
		padding: 40px 0;

		.offer-tiles-wrapper {
			flex-direction: column;
			align-items: center;

			.tile {
				margin-top: 20px;
			}
		}
	}


	section.section-employer {
		text-align: center;

		.employer-overlay {
			width: 100%;

			.overlay-wrapper {
				align-items: center;
			}
		}
	}

	section.section-workers {
		text-align: center;

		.workers-content {
			.workers-description {
				align-items: center;
				padding: 0 40px;
			}
		}
	}

	section.section-about {
		.about-wrapper {
			.wrapper-text {
				padding: 30px 0 0 0;

				.color-elem {
					display: none;
				}
			}
		}
	}

	section.section-offer-details {
		.details-wrapper {
			flex-direction: column;
			align-items: center;

			.details-item {
				width: 80%;
			}
		}
	}

	section.kontakt-section-content {
		.contact-wrapper {
			h2 {
				margin-bottom: 30px;
			}

			.contact-form {
				padding: 100px 0 0 0 !important;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

	section.section-employer {
		.employer-overlay {
			.overlay-wrapper {
				padding: 0 10px;
				align-items: center;

				h3 {
					font-size: 26px;
				}
			}
		}
	}

	section.section-workers {
		.workers-wrapper {
			.workers-content:nth-of-type(1) {
				width: 100%;

				.workers-opinion {
					width: 100%;
					padding: 50px 10px;
				}
			}
		}
	}
}