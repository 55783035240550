@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700|Poppins:400,500,700&display=swap');

@color1 : #f11924;
@color-heading: #232323;
@color-text: #212529;
@color-text-light: #444;

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul,
	ol,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700;
	}

	a {
		text-decoration: none !important;
	}

	.btn,
	.button-send {
		padding: 10px 15px;
		border: 1px solid #fff;
		border-radius: 0;
		background-color: none;
		color: #fff;
		transition: 250ms all ease-in;
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: lowercase;
		cursor: pointer;

		&:hover {
			color: @color1;
			background-color: rgba(255, 255, 255, 0.7);
		}

		&.btn-default {
			border: 1px solid #444;
			color: #444;

			&:hover {
				background-color: rgba(0, 0, 0, 0.4);
				color: #fff;
			}
		}
	}

	button {
		border: none;
		margin: 0;
		padding: 0;
	}
}

.system-alerts {
	width: 100%;
	text-align: center;

	.alert {
		border-radius: 0;
		border: none;
		height: 60px;
		display: flex;
		align-items: center;
		opacity: 0.9;
		transition: all 0.4s;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 18px;
			}

			p {
				color: #fff;
				padding: 0 50px;
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 18px;
				}
			}
		}

		&.alert-success {
			background: #76ad21;
		}

		&.alert-info {
			background: #218ead;
		}

		&.alert-warning {
			background: #c52b20;
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

nav.section-header {
	.border {
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	}

	a {
		transition: color 250ms ease-in;

		&:hover {
			color: @color1;
		}
	}

	// .container {
	// 	position: relative;
	// }

	.header-flexbox {
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-logo {
			img {
				height: 100px;
				width: auto;
			}
		}

		.flexbox-nav-wrapper {
			display: flex;
			width: 100%;

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before,
					&:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				position: relative;
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
				width: 100%;

				.flexbox-nav {
					width: 55%;
					display: flex;
					justify-content: space-around;

					li {
						a {
							color: #fff;
							font-size: 14px;
							cursor: pointer;
							letter-spacing: 1px;
							transition: color 250ms ease-in;

							&:hover {
								color: @color1;
							}
						}

						.dark {
							color: @color-text;
						}
					}
				}

				.nav-contact-list {
					display: flex;
					flex-direction: column;
					margin-left: 50px;

					li {
						a {
							font-size: 15px;
							color: #fff;
							font-weight: 600;
							letter-spacing: 1px;
							transition: color 250ms ease-in;

							&:hover {
								color: @color1;
							}
						}

						.dark {
							color: @color-text;
						}
					}
				}

				.color-elem-nav {
					position: absolute;
					top: -100%;
					right: 26%;
					width: 35px;
					height: 85px;
					border-radius: 10px;
					background: @color1;
					box-shadow: 0px 2px 5px #525252;

					@media (max-width: 1190px) {
						right: 31%;
					}
				}
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;

			.button-bar {
				height: 2px;
				width: 24px;
				background: #fff;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 4px;
					width: 27px;
				}

				&:nth-child(3) {
					margin-top: 4px;
					width: 30px;
				}
			}

			&:hover,
			&:focus {
				.button-bar {
					width: 30px !important;
				}
			}
		}
	}
}

section.section-welcome {
	height: 100vh;

	.welcome-text {
		display: none;
	}

	.welcome-content {
		height: 100%;

		.container {
			height: calc(100% - 130px);
		}

		.wrapper-carousel {
			padding-top: 150px;
		}

		.carousel-content {
			width: 50%;

			.owl-carousel {
				margin-left: 20px;

				.owl-stage {
					display: flex;
					align-items: center;
				}

				.owl-stage-outer {
					padding-left: 10px;
				}

				.heading {
					display: flex;
					flex-direction: column;
					justify-content: center;

					h1,
					h2 {
						font-weight: 700;
						color: #fff;
						font-size: 60px;
						letter-spacing: 2px;
					}
				}
			}
		}

		.heading-text-inner {
			border: 1px solid #fff;
			margin: 40px 0;
			height: 40%;
			width: 350px;

			p {
				line-height: 28px;
				font-size: 16px;
				color: #fff;
				padding: 20px;
			}
		}
	}
}

section.section-about {
	.about-wrapper {
		display: flex;

		.wrapper-img {
			display: flex;
			justify-content: center;
			width: 40%;
			height: 450px;
			margin-top: -20px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.wrapper-text {
			position: relative;
			padding: 80px 0 0 150px;
			display: flex;
			flex-direction: column;
			width: 60%;

			h3 {
				color: @color-heading;
				font-size: 46px;
				font-weight: 700;
				letter-spacing: 2px;
			}

			p {
				line-height: 30px;
				margin-top: 30px;
			}

			.color-elem {
				position: absolute;
				top: 90px;
				left: 0;
				width: 70px;
				height: 150px;
				background: @color1;
			}
		}
	}
}

section.section-offer {
	padding: 70px 0;

	.offer-tiles-wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		width: 100%;

		.tile {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			padding: 30px;
			width: 25%;

			h3 {
				font-size: 14px;
				margin-top: 20px;
				line-height: 24px;
			}

			p {
				font-size: 13px;
				margin-top: 10px;
				line-height: 24px;
			}
		}
	}
}

section.section-employer {
	display: flex;
	justify-content: flex-end;
	height: 450px;

	.employer-overlay {
		width: 50%;
		background: rgba(0, 0, 0, 0.7);

		.container {
			height: 100%;
		}

		.overlay-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			height: 100%;
			padding: 0 100px;

			h3 {
				color: @color1;
				text-transform: uppercase;
				font-size: 35px;
				letter-spacing: 2px;
				font-weight: 700;
			}

			span {
				color: @color1;
				margin-top: 10px;
				font-size: 18px;
				font-weight: 500;
			}

			p {
				color: #fff;
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 1px;
				margin-top: 20px;
				font-weight: 300;
				width: 90%;
			}

			.btn {
				margin-top: 30px;
			}
		}
	}
}

section.section-workers {
	.workers-wrapper {
		display: flex;
		background-color: #eae8e7;
	}

	.workers-content {
		display: flex;
		align-items: center;
		width: 50%;
		background-color: #fff;
		transform: translateY(-20%);

		&:nth-of-type(2) {
			margin-top: 0;
			background-color: #eae8e7;
			transform: translateY(0%);
		}

		.workers-opinion,
		.workers-description {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 50px 100px;

			.container {
				height: 100%;
			}

			h3 {
				color: @color-text-light;
				text-transform: uppercase;
				font-size: 35px;
				letter-spacing: 2px;
				font-weight: 700;
			}

			span {
				color: @color-text-light;
				display: inline-block;
				margin-top: 10px;
				font-size: 18px;
				font-weight: 500;
			}

			p {
				line-height: 28px;
				font-weight: 300;
				font-size: 14px;
				margin-top: 20px;
			}

			.btn {
				margin-top: 30px;
			}

			.item {
				span {
					display: inline-block;
					margin-top: 20px;
				}
			}
		}

		.workers-opinion {
			.owl-theme {
				.owl-dots {
					padding-top: 30px;

					.owl-dot.active {
						span {
							background: @color1;
						}
					}
				}
			}

			.owl-carousel-opinion {
				padding: 20px 0;
			}
		}
	}
}

section.section-offer-details {
	padding: 30px 0 60px 0;
	background: #eae8e7;

	.details-wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;

		.details-item {

			display: flex;
			flex-direction: column;
			position: relative;
			width: 22%;
			height: 200px;
			cursor: pointer;
			color: #fff;
			margin-bottom: 3%;
			background-color: rgba(255, 255, 255, 0.8);
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
			transition: box-shadow 250ms ease-in;

			&:hover {
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
			}

			&:hover .item-overlay {
				height: 45%;
				opacity: 0.9;
			}
		}

		.item-overlay {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 40%;
			opacity: 0.7;
			background-color: #fff;
			border: none;
			box-shadow: 1px 1px 20px rgba(255, 255, 255, 0.7);
			transition: all 250ms ease-in;

			h4 {
				position: absolute;
				bottom: 0;
				left: 10px;
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 10px;
				color: @color-heading;
			}
		}
	}
}

section.section-partners {
	padding: 10px 0;

	.owl-carousel-partners {
		.item {
			img {
				width: 100px;
				height: auto;
			}
		}
	}
}

section.kontakt-section-content {
	padding: 60px 0 100px 0;

	h2 {
		color: @color-heading;
		font-size: 45px;
		font-weight: 700;
		letter-spacing: 2px;
	}

	.contact-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		.contact-column {
			display: flex;

			.text {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			p {
				font-size: 22px;
			}

			a {
				color: @color-text;
				font-weight: 500;
				transition: color 250ms ease-in;

				&:hover {
					color: @color1;
				}
			}

			.iframe-map {
				position: relative;
				width: 50%;
				height: 350px;

				iframe {
					height: 100%;
					max-height: 350px;
					width: 100%;
					border: none;
				}

				.color-elem {
					position: absolute;
					top: 90px;
					left: -8%;
					width: 300px;
					height: 300px;
					background: @color1;
					z-index: -1;
				}
			}
		}
	}
}

// forms

form {
	padding-top: 40px;
	width: 70%;
	margin: 0 auto;

	label {
		margin-bottom: 0;
		font-size: 15px;
	}

	.form-control {
		border: none;
		border-radius: 0;
		border-bottom: 1px solid #ccc;
		height: auto;
		padding: 15px 0;

		&:focus {
			border-bottom: 1.25px solid #444;
		}
	}

	textarea::-webkit-input-placeholder,
	input::-webkit-input-placeholder,
	select,
	label.form-check-label {
		font-size: 13px;
	}

	.form-row {
		margin-top: 20px;

		.form-group {
			padding-right: 20px;

			p {
				font-size: 13px;
			}
		}
	}

	.button-send {
		margin-top: 20px;
	}
}

.contact-form {
	width: 100%;
	padding-top: 120px;

	h2 {
		text-align: center;
		line-height: 50px;

		span {
			font-weight: 500;
			font-size: 20px;
			color: @color1;
		}
	}
}

// modals

.modal-content {
	background: #fff;

	h5 {
		font-size: 28px;
	}

	.modal-body {
		padding: 2rem;

		p {
			font-size: 14px;
			margin-top: 10px;
			line-height: 24px;
		}

		.modal-footer {
			border-top: 0;
		}
	}
}

section.section-footer {
	padding: 50px 0;
	background-color: #232323;
	position: relative;
	overflow: hidden;

	.container:before {
		display: none;
	}

	.footer-flexbox {
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		position: relative;

		.logo {
			height: 150px;
		}

		.flexbox-service {
			p {
				margin-top: 10px;
				font-size: 15px;
				font-weight: 400;
				line-height: 28px;
				color: #fff;
			}

			.footer-heading {
				margin-top: 0;
				padding-bottom: 10px;
				font-size: 18px;
				color: #fcfcfc;
			}

			ul {
				li {
					margin-top: 10px;
					color: #fff;
					font-size: 14px;

					a {
						transition: color 250ms ease-in;
						color: #fff;
						font-size: 14px;

						&:hover {
							color: @color1;
						}

						b {
							font-size: 16px;
						}

						span {
							font-size: 10px;
						}
					}

					// p {
					// 	font-size: 12px;
					// 	color: #212529;

					// 	b {
					// 		font-weight: 400;
					// 		font-size: 14px;
					// 	}

					// 	span {
					// 		font-size: 10px;
					// 	}
					// }
				}
			}

			.list-social {
				display: flex;
				align-items: center;

				li {
					margin-right: 20px;

					a {
						img {
							height: 20px;
							transition: transform 0.2s;

							&:hover {
								transform: scale(1.1);
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.footer-credits {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: 50px;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		p {
			font-size: 13px;
			color: #fff;
			font-weight: 300;

			a {
				color: @color1;
			}
		}
	}

	.alert {
		position: relative;
		margin: 50px 0 0 0;
		padding: 50px 0 0 0;
		border-radius: 0;
		background: none;
		border: none;
		border-top: 1px solid rgba(255, 255, 255, 0.2);

		p {
			font-size: 13px;
			color: #fff;
			font-weight: 300;

			a {
				color: #fcfcfc;
				cursor: pointer;
				transition: all 0.3s;

				&:hover {
					color: @color1;
				}
			}
		}
	}
}