@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-weight: 700;
}
body a {
  text-decoration: none !important;
}
body .btn,
body .button-send {
  padding: 10px 15px;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: none;
  color: #fff;
  transition: 250ms all ease-in;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: lowercase;
  cursor: pointer;
}
body .btn:hover,
body .button-send:hover {
  color: #f11924;
  background-color: rgba(255, 255, 255, 0.7);
}
body .btn.btn-default,
body .button-send.btn-default {
  border: 1px solid #444;
  color: #444;
}
body .btn.btn-default:hover,
body .button-send.btn-default:hover {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}
body button {
  border: none;
  margin: 0;
  padding: 0;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
nav.section-header .border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
nav.section-header a {
  transition: color 250ms ease-in;
}
nav.section-header a:hover {
  color: #f11924;
}
nav.section-header .header-flexbox {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 100px;
  width: auto;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  display: flex;
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  width: 55%;
  display: flex;
  justify-content: space-around;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 1px;
  transition: color 250ms ease-in;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #f11924;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dark {
  color: #212529;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .nav-contact-list {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .nav-contact-list li a {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  transition: color 250ms ease-in;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .nav-contact-list li a:hover {
  color: #f11924;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .nav-contact-list li .dark {
  color: #212529;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .color-elem-nav {
  position: absolute;
  top: -100%;
  right: 26%;
  width: 35px;
  height: 85px;
  border-radius: 10px;
  background: #f11924;
  box-shadow: 0px 2px 5px #525252;
}
@media (max-width: 1190px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .color-elem-nav {
    right: 31%;
  }
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
section.section-welcome {
  height: 100vh;
}
section.section-welcome .welcome-text {
  display: none;
}
section.section-welcome .welcome-content {
  height: 100%;
}
section.section-welcome .welcome-content .container {
  height: calc(100% - 130px);
}
section.section-welcome .welcome-content .wrapper-carousel {
  padding-top: 150px;
}
section.section-welcome .welcome-content .carousel-content {
  width: 50%;
}
section.section-welcome .welcome-content .carousel-content .owl-carousel {
  margin-left: 20px;
}
section.section-welcome .welcome-content .carousel-content .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-content .carousel-content .owl-carousel .owl-stage-outer {
  padding-left: 10px;
}
section.section-welcome .welcome-content .carousel-content .owl-carousel .heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h1,
section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h2 {
  font-weight: 700;
  color: #fff;
  font-size: 60px;
  letter-spacing: 2px;
}
section.section-welcome .welcome-content .heading-text-inner {
  border: 1px solid #fff;
  margin: 40px 0;
  height: 40%;
  width: 350px;
}
section.section-welcome .welcome-content .heading-text-inner p {
  line-height: 28px;
  font-size: 16px;
  color: #fff;
  padding: 20px;
}
section.section-about .about-wrapper {
  display: flex;
}
section.section-about .about-wrapper .wrapper-img {
  display: flex;
  justify-content: center;
  width: 40%;
  height: 450px;
  margin-top: -20px;
}
section.section-about .about-wrapper .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
section.section-about .about-wrapper .wrapper-text {
  position: relative;
  padding: 80px 0 0 150px;
  display: flex;
  flex-direction: column;
  width: 60%;
}
section.section-about .about-wrapper .wrapper-text h3 {
  color: #232323;
  font-size: 46px;
  font-weight: 700;
  letter-spacing: 2px;
}
section.section-about .about-wrapper .wrapper-text p {
  line-height: 30px;
  margin-top: 30px;
}
section.section-about .about-wrapper .wrapper-text .color-elem {
  position: absolute;
  top: 90px;
  left: 0;
  width: 70px;
  height: 150px;
  background: #f11924;
}
section.section-offer {
  padding: 70px 0;
}
section.section-offer .offer-tiles-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
section.section-offer .offer-tiles-wrapper .tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 30px;
  width: 25%;
}
section.section-offer .offer-tiles-wrapper .tile h3 {
  font-size: 14px;
  margin-top: 20px;
  line-height: 24px;
}
section.section-offer .offer-tiles-wrapper .tile p {
  font-size: 13px;
  margin-top: 10px;
  line-height: 24px;
}
section.section-employer {
  display: flex;
  justify-content: flex-end;
  height: 450px;
}
section.section-employer .employer-overlay {
  width: 50%;
  background: rgba(0, 0, 0, 0.7);
}
section.section-employer .employer-overlay .container {
  height: 100%;
}
section.section-employer .employer-overlay .overlay-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 0 100px;
}
section.section-employer .employer-overlay .overlay-wrapper h3 {
  color: #f11924;
  text-transform: uppercase;
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 700;
}
section.section-employer .employer-overlay .overlay-wrapper span {
  color: #f11924;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}
section.section-employer .employer-overlay .overlay-wrapper p {
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
  margin-top: 20px;
  font-weight: 300;
  width: 90%;
}
section.section-employer .employer-overlay .overlay-wrapper .btn {
  margin-top: 30px;
}
section.section-workers .workers-wrapper {
  display: flex;
  background-color: #eae8e7;
}
section.section-workers .workers-content {
  display: flex;
  align-items: center;
  width: 50%;
  background-color: #fff;
  transform: translateY(-20%);
}
section.section-workers .workers-content:nth-of-type(2) {
  margin-top: 0;
  background-color: #eae8e7;
  transform: translateY(0%);
}
section.section-workers .workers-content .workers-opinion,
section.section-workers .workers-content .workers-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 100px;
}
section.section-workers .workers-content .workers-opinion .container,
section.section-workers .workers-content .workers-description .container {
  height: 100%;
}
section.section-workers .workers-content .workers-opinion h3,
section.section-workers .workers-content .workers-description h3 {
  color: #444;
  text-transform: uppercase;
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 700;
}
section.section-workers .workers-content .workers-opinion span,
section.section-workers .workers-content .workers-description span {
  color: #444;
  display: inline-block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}
section.section-workers .workers-content .workers-opinion p,
section.section-workers .workers-content .workers-description p {
  line-height: 28px;
  font-weight: 300;
  font-size: 14px;
  margin-top: 20px;
}
section.section-workers .workers-content .workers-opinion .btn,
section.section-workers .workers-content .workers-description .btn {
  margin-top: 30px;
}
section.section-workers .workers-content .workers-opinion .item span,
section.section-workers .workers-content .workers-description .item span {
  display: inline-block;
  margin-top: 20px;
}
section.section-workers .workers-content .workers-opinion .owl-theme .owl-dots {
  padding-top: 30px;
}
section.section-workers .workers-content .workers-opinion .owl-theme .owl-dots .owl-dot.active span {
  background: #f11924;
}
section.section-workers .workers-content .workers-opinion .owl-carousel-opinion {
  padding: 20px 0;
}
section.section-offer-details {
  padding: 30px 0 60px 0;
  background: #eae8e7;
}
section.section-offer-details .details-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
section.section-offer-details .details-wrapper .details-item {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 22%;
  height: 200px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 3%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: box-shadow 250ms ease-in;
}
section.section-offer-details .details-wrapper .details-item:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
section.section-offer-details .details-wrapper .details-item:hover .item-overlay {
  height: 45%;
  opacity: 0.9;
}
section.section-offer-details .details-wrapper .item-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
  opacity: 0.7;
  background-color: #fff;
  border: none;
  box-shadow: 1px 1px 20px rgba(255, 255, 255, 0.7);
  transition: all 250ms ease-in;
}
section.section-offer-details .details-wrapper .item-overlay h4 {
  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #232323;
}
section.section-partners {
  padding: 10px 0;
}
section.section-partners .owl-carousel-partners .item img {
  width: 100px;
  height: auto;
}
section.kontakt-section-content {
  padding: 60px 0 100px 0;
}
section.kontakt-section-content h2 {
  color: #232323;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 2px;
}
section.kontakt-section-content .contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.kontakt-section-content .contact-wrapper .contact-column {
  display: flex;
}
section.kontakt-section-content .contact-wrapper .contact-column .text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.kontakt-section-content .contact-wrapper .contact-column p {
  font-size: 22px;
}
section.kontakt-section-content .contact-wrapper .contact-column a {
  color: #212529;
  font-weight: 500;
  transition: color 250ms ease-in;
}
section.kontakt-section-content .contact-wrapper .contact-column a:hover {
  color: #f11924;
}
section.kontakt-section-content .contact-wrapper .contact-column .iframe-map {
  position: relative;
  width: 50%;
  height: 350px;
}
section.kontakt-section-content .contact-wrapper .contact-column .iframe-map iframe {
  height: 100%;
  max-height: 350px;
  width: 100%;
  border: none;
}
section.kontakt-section-content .contact-wrapper .contact-column .iframe-map .color-elem {
  position: absolute;
  top: 90px;
  left: -8%;
  width: 300px;
  height: 300px;
  background: #f11924;
  z-index: -1;
}
form {
  padding-top: 40px;
  width: 70%;
  margin: 0 auto;
}
form label {
  margin-bottom: 0;
  font-size: 15px;
}
form .form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  height: auto;
  padding: 15px 0;
}
form .form-control:focus {
  border-bottom: 1.25px solid #444;
}
form textarea::-webkit-input-placeholder,
form input::-webkit-input-placeholder,
form select,
form label.form-check-label {
  font-size: 13px;
}
form .form-row {
  margin-top: 20px;
}
form .form-row .form-group {
  padding-right: 20px;
}
form .form-row .form-group p {
  font-size: 13px;
}
form .button-send {
  margin-top: 20px;
}
.contact-form {
  width: 100%;
  padding-top: 120px;
}
.contact-form h2 {
  text-align: center;
  line-height: 50px;
}
.contact-form h2 span {
  font-weight: 500;
  font-size: 20px;
  color: #f11924;
}
.modal-content {
  background: #fff;
}
.modal-content h5 {
  font-size: 28px;
}
.modal-content .modal-body {
  padding: 2rem;
}
.modal-content .modal-body p {
  font-size: 14px;
  margin-top: 10px;
  line-height: 24px;
}
.modal-content .modal-body .modal-footer {
  border-top: 0;
}
section.section-footer {
  padding: 50px 0;
  background-color: #232323;
  position: relative;
  overflow: hidden;
}
section.section-footer .container:before {
  display: none;
}
section.section-footer .footer-flexbox {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .logo {
  height: 150px;
}
section.section-footer .footer-flexbox .flexbox-service p {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
}
section.section-footer .footer-flexbox .flexbox-service .footer-heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #fcfcfc;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  transition: color 250ms ease-in;
  color: #fff;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #f11924;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 20px;
  transition: transform 0.2s;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img:hover {
  transform: scale(1.1);
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 13px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #f11924;
}
section.section-footer .alert {
  position: relative;
  margin: 50px 0 0 0;
  padding: 50px 0 0 0;
  border-radius: 0;
  background: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
section.section-footer .alert p {
  font-size: 13px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #fcfcfc;
  cursor: pointer;
  transition: all 0.3s;
}
section.section-footer .alert p a:hover {
  color: #f11924;
}
@media (max-width: 1366.98px) {
  section.section-welcome .welcome-content .wrapper-carousel {
    padding-top: 100px;
  }
}
@media (max-width: 1189.98px) {
  .color-elem-nav {
    right: 31%;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .nav-contact-list {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .color-elem-nav {
    right: -5%;
    top: -270%;
  }
  section.section-welcome .welcome-content .heading-text-inner {
    width: 300px;
  }
  section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h1,
  section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h2 {
    font-size: 40px;
  }
  section.section-employer .employer-overlay .overlay-wrapper {
    padding: 0 30px;
  }
  section.section-employer .employer-overlay .overlay-wrapper h3 {
    font-size: 30px;
  }
  section.section-workers .workers-content .workers-description {
    padding: 0 30px;
  }
  section.section-workers .workers-content .workers-description h3 {
    font-size: 30px;
  }
  section.section-workers .workers-content .workers-opinion {
    padding: 50px;
  }
  section.section-offer-details .details-wrapper .details-item {
    height: 150px;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .nav-button .button-bar {
    background: #f11924;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li form {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    color: #444;
    font-size: 20px;
    font-weight: 400;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-of-type a {
    color: #f11924;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.section-welcome .welcome-content .heading-text-inner {
    width: 240px;
  }
  section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h1,
  section.section-welcome .welcome-content .carousel-content .owl-carousel .heading h2 {
    font-size: 30px;
  }
  section.section-about .about-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-about .about-wrapper .wrapper-text {
    width: 100%;
  }
  section.section-about .about-wrapper .wrapper-img {
    width: 80%;
    height: 300px;
  }
  section.section-offer .offer-tiles-wrapper .tile {
    padding: 10px;
  }
  section.section-offer .offer-tiles-wrapper .tile .icon img {
    width: 40px;
  }
  section.section-offer-details {
    padding: 0 0 60px 0;
  }
  section.section-offer-details .details-wrapper .details-item {
    height: 100px;
  }
  section.section-offer-details .details-wrapper .item-overlay h4 {
    font-size: 14px;
  }
  section.kontakt-section-content {
    padding: 60px 0 100px 0;
  }
  section.kontakt-section-content h2 {
    font-size: 40px;
  }
  section.kontakt-section-content .contact-wrapper .contact-column {
    flex-direction: column;
  }
  section.kontakt-section-content .contact-wrapper .contact-column .text {
    width: 100%;
  }
  section.kontakt-section-content .contact-wrapper .contact-column .iframe-map {
    width: 100%;
    margin-top: 50px;
    height: 250px;
  }
  section.kontakt-section-content .contact-wrapper .contact-column .iframe-map iframe {
    max-height: 250px;
  }
  section.kontakt-section-content .contact-wrapper .contact-column .iframe-map .color-elem {
    top: 23%;
    left: -2%;
    width: 250px;
    height: 200px;
  }
  section.kontakt-section-content .contact-wrapper .contact-form form {
    width: 100%;
    margin: 0;
  }
  section.section-footer {
    padding: 0;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    text-align: center;
    margin-top: 20px;
  }
}
@media (max-width: 680.98px) {
  section.section-workers .workers-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-workers .workers-wrapper .workers-content {
    width: 100%;
  }
  section.section-workers .workers-wrapper .workers-content:nth-of-type(1) {
    width: 80%;
    transform: translateY(0);
  }
  section.section-workers .workers-wrapper .workers-content:nth-of-type(2) {
    padding: 50px 0;
  }
  section.section-workers .workers-wrapper .workers-content .workers-description h3 {
    font-size: 30px;
  }
  section.section-workers .workers-wrapper .workers-content .workers-opinion {
    width: 100%;
  }
  section.section-offer-details .details-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-offer-details .details-wrapper .details-item {
    width: 50%;
    height: 250px;
  }
  section.section-offer-details .details-wrapper .details-item .item-overlay h4 {
    font-size: 18px;
  }
}
@media (max-width: 579.98px) {
  .modal .contact-form {
    padding: 0 !important;
  }
  section.section-welcome .welcome-text {
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  section.section-welcome .welcome-text .heading-text-inner {
    width: 80%;
  }
  section.section-welcome .welcome-content .carousel-content {
    display: none;
  }
  section.section-offer {
    padding: 40px 0;
  }
  section.section-offer .offer-tiles-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-offer .offer-tiles-wrapper .tile {
    margin-top: 20px;
  }
  section.section-employer {
    text-align: center;
  }
  section.section-employer .employer-overlay {
    width: 100%;
  }
  section.section-employer .employer-overlay .overlay-wrapper {
    align-items: center;
  }
  section.section-workers {
    text-align: center;
  }
  section.section-workers .workers-content .workers-description {
    align-items: center;
    padding: 0 40px;
  }
  section.section-about .about-wrapper .wrapper-text {
    padding: 30px 0 0 0;
  }
  section.section-about .about-wrapper .wrapper-text .color-elem {
    display: none;
  }
  section.section-offer-details .details-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.section-offer-details .details-wrapper .details-item {
    width: 80%;
  }
  section.kontakt-section-content .contact-wrapper h2 {
    margin-bottom: 30px;
  }
  section.kontakt-section-content .contact-wrapper .contact-form {
    padding: 100px 0 0 0 !important;
  }
}
@media (max-width: 400px) {
  section.section-employer .employer-overlay .overlay-wrapper {
    padding: 0 10px;
    align-items: center;
  }
  section.section-employer .employer-overlay .overlay-wrapper h3 {
    font-size: 26px;
  }
  section.section-workers .workers-wrapper .workers-content:nth-of-type(1) {
    width: 100%;
  }
  section.section-workers .workers-wrapper .workers-content:nth-of-type(1) .workers-opinion {
    width: 100%;
    padding: 50px 10px;
  }
}
